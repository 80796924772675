import { likeRegExp } from '@/offline/database'
import _ from '@/utils/lodash'

export default {
  _selectSistemaTelefonoBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.sistema_telefono)
      .innerJoin(
        tables.cliente_telefono,
        tables.sistema_telefono.idcliente_telefono.eq(tables.cliente_telefono.idcliente_telefono)
      )
      .innerJoin(
        tables.ttelefono,
        tables.cliente_telefono.idttelefono.eq(tables.ttelefono.idttelefono)
      )
      .orderBy(tables.cliente_telefono.nombre)
  },
  _selectClienteTelefonoBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.cliente_telefono)
      .innerJoin(
        tables.ttelefono,
        tables.cliente_telefono.idttelefono.eq(tables.ttelefono.idttelefono)
      )
      .orderBy(tables.cliente_telefono.nombre)
  },
  async desdeSistema (Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    const sistemaTelefonoCount = await Vue.$offline.sistemaTelefono.select({
      where: Vue.$offline.db.op.and(
        tables.sistema_telefono.idsistema.eq(idsistema),
        tables.sistema_telefono.estado.gt(0),
      )
    })
    return sistemaTelefonoCount.length > 0
  },
  async selectClienteTelefono (Vue, filter, search, sorter, page, idcliente, idsistema) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.cliente_telefono.nombre.match(reQ),
          tables.cliente_telefono.email.match(reQ),
          tables.cliente_telefono.telefono.match(reQ),
        )
      )
    }
    if (filter.nombre.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.cliente_telefono.nombre.match(likeRegExp(filter.nombre.value))
        )
      )
    }
    if (filter.telefono.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.cliente_telefono.telefono.match(likeRegExp(filter.telefono.value))
        )
      )
    }
    if (filter.email.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.cliente_telefono.email.match(likeRegExp(filter.email.value))
        )
      )
    }
    // los contactos del sistema son: los de SISTEMA_TELEFONO si tiene + CLIENTE_TELEFONO que tengan el check de `para_todos_los_sistemas`
    if (!!idsistema) {
      let whereSistemaTelefono = []
      whereSistemaTelefono.push(
        ...where,
        Vue.$offline.db.op.and(
          tables.sistema_telefono.idsistema.eq(idsistema),
          tables.sistema_telefono.estado.gt(0),
          ...whereSearch,
          ...whereFilter
        )
      )
      let [datasetSistemaTelefono] = [
        await this._selectSistemaTelefonoBase(Vue)
          .where(...whereSistemaTelefono)
          .limit(Vue.$offline.db.ITEMS_PER_PAGE)
          .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
          .exec(),
        []
      ]
      let whereClienteTelefono = []
      whereClienteTelefono.push(
        ...where,
        Vue.$offline.db.op.and(
          tables.cliente_telefono.idcliente.eq(idcliente),
          tables.cliente_telefono.estado.gt(0),
          tables.cliente_telefono.para_todos_los_sistemas.eq(1),
          ...whereSearch,
          ...whereFilter
        )
      )
      let [datasetClienteTelefono] = [
        await this._selectClienteTelefonoBase(Vue)
          .where(...whereClienteTelefono)
          .limit(Vue.$offline.db.ITEMS_PER_PAGE)
          .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
          .exec(),
        []
      ]
      return [_.uniqBy(_.concat(datasetSistemaTelefono, datasetClienteTelefono),'cliente_telefono.idcliente_telefono')]
    } else {
      where.push(
        Vue.$offline.db.op.and(
          tables.cliente_telefono.idcliente.eq(idcliente),
          tables.cliente_telefono.estado.gt(0),
          ...whereSearch,
          ...whereFilter
        )
      )
      return [
        await this._selectClienteTelefonoBase(Vue)
          .where(...where)
          .limit(Vue.$offline.db.ITEMS_PER_PAGE)
          .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
          .exec(),
        []
      ]
    }
  },
  async selectClienteTelefonoRows (Vue, pks, idsistema) {
    const tables = Vue.$offline.db.tables
    let desdeSistema = !!idsistema && (await this.desdeSistema(Vue, idsistema))
    let esClienteTelefono = false
    // puede ser que esté consultando el sistema pero sea un telefono marcado `para_todos_los_sistemas`
    if (desdeSistema) {
      const sistemaTelefono = await this._selectSistemaTelefonoBase(Vue)
        .where(
          Vue.$offline.db.op.and(
            tables.sistema_telefono.idsistema.eq(idsistema),
            tables.cliente_telefono.idcliente_telefono.in(pks)
          )
        )
        .exec()
      if (sistemaTelefono.length > 0) {
        return sistemaTelefono
      } else {
        esClienteTelefono = true
      }
    } else {
      esClienteTelefono = true
    }
    if (esClienteTelefono) {
      return await this._selectClienteTelefonoBase(Vue)
        .where(tables.cliente_telefono.idcliente_telefono.in(pks))
        .exec()
    }
  }
}
