<template>
  <div>
    <v-chip
      v-if="esContactoSistema"
      :color="CLIENTE_TELEFONO.colores.contacto_sistema"
      dark
      label
      small
      class="ml-1 mt-1"
    >
      Contacto de sistema
    </v-chip>
  </div>
</template>

<script>
import { CLIENTE_TELEFONO } from '@/utils/consts'

export default {
  props: {
    esContactoSistema:{
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      CLIENTE_TELEFONO,
    }
  },
}
</script>
