<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <extra-subtitle
        :es-contacto-sistema="formattedItem.esContactoSistema"
      />
    </v-list-item-content>
    <v-list-item-icon>
      <v-btn
        v-if="hasPermBorrar"
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'
import { nonEmpty } from '@/utils/templates'
import ExtraSubtitle from '../../components/ClienteTelefonoExtraSubtitle'

export default {
  components: {
    ExtraSubtitle
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    hasPermBorrar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$options.filters.linebreaksBr(nonEmpty`${item.ttelefono.descripcion} ${item.cliente_telefono.nombre}`)
      item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
        ${item.cliente_telefono.telefono}
        ${item.cliente_telefono.email}
      `)
      item.avatar = this.$options.filters.initials(item.ttelefono.descripcion)
      item.avatarColor = stringToHslColor(calculateMd5(item.ttelefono.descripcion))
      item.esContactoSistema = !!item.sistema_telefono?.idsistema_telefono
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
